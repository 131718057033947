<template>
  <div id="AboutPage">
    <div class="Head noselect">
      <!-- Navbar -->
      <div
        class="NavWrapper"
        :style="
          VideoInBG
            ? ''
            : 'top: 0%; backdrop-filter: blur(20px); background-color: #0000008c;'
        "
      >
        <LandingNavbar
          :SendRequestBusiness="
            () => {
              showRegistrationModal = true;
            }
          "
        />
      </div>

      <div class="NoVideoWrapper" :style="VideoInBG ? '' : 'display: none;'">
        <div class="NoVideoContent">
          <div class="Text">
            <span class="Title"
              ><span class="BOLD">Наша цель</span> — поиск, развитие<br />и
              привлечение в профессию<br />талантливых разработчиков</span
            >
            <span class="Desc">
              Октагон — единая платформа для получения<br />компетенций и опыта
              работы в IT.
            </span>
          </div>
          <div class="PlayButton" @click="enableVideo">
            <div class="PlayIMG"></div>
            <span>Посмотреть<br />видео о нас</span>
          </div>
        </div>

        <div class="LeftLightEffect"></div>
        <div class="RightLightEffect"></div>
        <div class="OctagonTransparentParticle"></div>
      </div>

      <div class="VideoWrapper">
        <div
          :class="{ Video: true, VideoIsActive: !VideoInBG }"
          :style="
            VideoInBG
              ? 'opacity: 0.15;'
              : 'opacity: 1; height: 100%; transform: none;'
          "
        >
          <video
            ref="videoPlayer"
            :muted="VideoInBG"
            :loop="VideoInBG"
            controls
            class="video-js vjs-default-skin"
          ></video>
        </div>
      </div>
    </div>
    <div class="Compatible">
      <span class="Title">Кому подойдёт Октагон</span>
      <div class="CompList">
        <div
          v-for="(comp, cindx) in CompList"
          :key="`${cindx}${comp.Title}`"
          class="Card"
        >
          <div :class="`CompIMG${cindx + 1}`"></div>
          <span class="Title">{{ comp.Title }}</span>
          <ul class="DescList">
            <li
              v-for="(cl, clindx) in comp.List"
              :key="clindx"
              v-html="cl"
            ></li>
          </ul>
        </div>
      </div>

      <div class="LineIMG"></div>
    </div>
    <div class="Steps">
      <span class="Title">Как устроена платформа Октагон</span>
      <div class="StepsList">
        <div
          class="Step"
          v-for="(step, sindx) in StepsList"
          :key="`${sindx}${step.Title}`"
        >
          <div :class="`StepIMG${sindx + 1}`"></div>
          <span class="Title">{{ step.Title }}</span>
          <span class="Desc" v-html="step.Desc"></span>
        </div>
      </div>
    </div>
    <div class="FAQ">
      <span class="Title">Мы ответим на ваши вопросы</span>
      <span class="Desc"
        >Расскажем подробнее о платформе и ответим на все вопросы</span
      >

      <a
        class="Button noselect"
        href="https://t.me/octagon_help_bot"
        target="_blank"
        >Задать вопрос</a
      >

      <div class="Light1"></div>
      <div class="LineIMG"></div>
    </div>
    <div class="OurTeam">
      <span>Наша команда</span>
      <div class="TeamList">
        <div
          class="Card"
          v-for="(tmemb, tindx) in TeamList"
          :key="`${tindx}${tmemb.FLName}`"
          :style="`z-index: ${100 - tindx};`"
        >
          <div class="PhotoWrap">
            <div class="TeamIMG" :style="getMemberIMG(tmemb.IMGName)"></div>
            <div class="BubbleContainer" v-if="tmemb.Bubble != null">
              <div class="BubbleIMG"></div>
              <span class="BubbleContent" v-html="tmemb.Bubble"></span>
            </div>
          </div>
          <span class="LastNameFirstName">{{ tmemb.FLName }}</span>
          <span class="Text" v-html="tmemb.Desc"></span>
        </div>
      </div>

      <div class="Light1"></div>
    </div>
    <div class="SuccessfullCases">
      <span>Успешные кейсы</span>
      <div class="CasesList">
        <div
          class="Card"
          v-for="(scase, scindex) in CasesList"
          :key="`${scase}${scindex}`"
        >
          <span>{{ scase.Title }}</span>
          <div class="Desc">
            <span class="StaticText">Заказчик</span>
            <span class="CaseDesc" v-html="scase.Client"></span>

            <span class="StaticText">Задача</span>
            <span class="CaseDesc" v-html="scase.Task"></span>

            <span class="StaticText">Реализовано</span>
            <span class="CaseDesc" v-html="scase.Solution"></span>
          </div>
        </div>
      </div>

      <div class="LineIMG"></div>
    </div>
    <div class="OurPartners">
      <span>С нами сотрудничают</span>
      <div class="PartnersList">
        <div class="OneCIMG"></div>
        <div class="BaikalShinaIMG"></div>
        <div class="MirMebeliIMG"></div>
        <div class="ForusIMG"></div>
        <div class="OblastMolodihIMG"></div>
      </div>
    </div>
    <div class="ParticipantsFeedbacks">
      <span class="Header">Отзывы участников</span>
      <div class="List">
        <div
          class="Card"
          v-for="(feed, findx) in FeedbacksList"
          :key="`${findx}${feed.FLName}`"
        >
          <div class="Info">
            <div class="IMG" :style="getFeedbackIMG(feed.IMGName)"></div>
            <div class="Participant">
              <span class="NameSurname">{{ feed.FLName }}</span>
              <span class="ShortPInfo">{{ feed.From }}</span>
            </div>
          </div>
          <span class="Text" v-html="feed.Text"></span>
        </div>
      </div>
    </div>
    <div class="StayWithUs">
      <span class="Title">Присоединяйтесь к нам</span>
      <span class="Desc">С нами интересно, полезно, перспективно!</span>
      <div class="Buttons">
        <div
          class="ButtonGradient noselect"
          @click.native="showRegistrationModal = true"
        >
          <span>Зарегистрироваться</span>
          <div class="ArrowRightUp"></div>
        </div>
        <router-link to="/project/projectsList">
          <div class="ButtonGradientOutside noselect">
            <span>Список проектов</span>
            <div class="ArrowRightUp"></div>
          </div>
        </router-link>
      </div>

      <div class="Light1"></div>
    </div>

    <SendRequest
      @sendFalseToParent="sendFalseToParent"
      :key="`${sendFalseToParent}${setTypeForModal}`"
      :setType="setTypeForModal"
      :showModal="showRequestModal"
    />

    <RegistrationModal
      @sendCloseRegistrationModal="sendCloseRegistrationModal"
      :openOrgRegistration="openOrgRegistration"
      :showModal="showRegistrationModal"
    />
  </div>
</template>

<script>
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import SendRequest from "@/components/Landing/SendRequest";
import RegistrationModal from "@/components/Landing/RegistrationModal";
import LandingNavbar from "@/components/Landing/Navbar";

import videojs from "video.js";

export default {
  components: { LandingNavbar, SendRequest, RegistrationModal },
  data() {
    return {
      CompList: [
        {
          Title: "Студентам",
          List: [
            "Прохождение<br/>практики",
            "Получение<br/>актуальных<br/>компетенций",
            "Решение задач<br/>бизнеса",
            "Реализация<br/>проекта",
          ],
        },
        {
          Title: "IT компаниям",
          List: [
            "Привлечение<br/>IT-кадров в свой<br/>штат",
            "Решение своих<br/>задач",
            "Решение задач<br/>своих клиентов",
          ],
        },
        {
          Title: "Бизнесу",
          List: [
            "Экономия на<br/>решении своих<br/>задач",
            "Привлечение<br/>IT-кадров в свой<br/>штат",
          ],
        },
        {
          Title: "Учебным заведениям",
          List: [
            "Поддержание рейтинга",
            "Выстроенная механика<br/>прохождения практики",
          ],
        },
      ],
      StepsList: [
        {
          Title: "Регистрация",
          Desc: "Заполни данные<br/>о себе в личном<br/>кабинете",
        },
        {
          Title: "Выбор задачи",
          Desc:
            "Выбери задачу от<br/>заказчика, либо<br/>предложи свою<br/>идею",
        },
        {
          Title: "Компетенции",
          Desc:
            "Получи новые<br/>компетенции,<br/>чтобы приступить<br/>к выполнению<br/>задач",
        },
        {
          Title: "Решение задачи",
          Desc:
            "Реши задачи<br/>от заказчика,<br/>а наш наставник<br/>поможет тебе",
        },
        {
          Title: "Набор баллов",
          Desc: "Копи баллы<br/>и обменивай<br/>их на ценные<br/>призы",
        },
      ],
      CasesList: [
        {
          Title: "Кнопка лояльности",
          Client: `
          Компания, занимающаяся поставкой автошин и<br/>
          дисков, автомобильных аккумуляторов и<br/>
          смазочных материалов.
          `,
          Task: `
          Разработать приложение для сбора обратной<br/>
          связи от клиентов с целью улучшения<br/>
          качества обслуживания.
          `,
          Solution: `
          Реализовано приложение на платформе 1С,<br/>
          устанавливаемое на планшет, которое<br/>
          позволяет клиентам оценить качество<br/>
          обслуживания, оставить обратную связь и<br/>
          записать голосовое сообщение о своём опыте.`,
        },
        {
          Title: "Распознавание лиц",
          Client: `
          Сеть салонов парикмахерских для мужчин.
          `,
          Task: `
          Необходимо определять стрижку, и дать<br/>
          возможность своим сотрудникам отслеживать<br/>
          свои показатели работоспособности.
          `,
          Solution: `
          Реализован алгоритм распознавания лиц на Python,<br/>
          который помогает узнавать клиента по лицу,<br/>
          подбирать варианты стрижек, определять его лояльность.<br/>
          Настроена возможность сотрудникам отслеживать<br/>
          данные о количестве клиентов и их оплатам.`,
        },
        {
          Title: `Сервис "Вахта"`,
          Client: `
          Строительная компания по ремонту и<br/>
          реконструкции автомобильных и ж/д дорог.
          `,
          Task: `
          Автоматизировать процесс подсчёта заработной<br/>
          платы, так как были недочёты, ошибки, процесс<br/>
          был долгим.
          `,
          Solution: `
          Реализовано обновление 1С:ЗУП до новой версии.<br/>
          Разработано мобильное приложение для ввода<br/>
          отработанных часов и их отправки в 1С:ЗУП.<br/>
          Реализовано проведение начисления зарплат<br/>
          из управленческого учёта в 1С:ЗУП.`,
        },
        {
          Title: `Написание драйвера с нуля`,
          Client: `
          Компания, занимающаяся продажей и<br/>
          сопровождением эквайринговых терминалов.
          `,
          Task: `
          Необходимо адаптировать существующий код<br/>
          драйвера под новую библиотеку подключаемого<br/>
          оборудования, разработанную 1С.
          `,
          Solution: `
          Реализован программный интерфейс драйвера<br/>
          для интеграции 1С с Android. Далее реализован<br/>
          интеграционный модуль драйвера 1С, включая<br/>
          определение логики работы, алгоритмы обмена<br/>
          данными и управления устройствами.<br/>
          Проведено тестирование работы драйвера.`,
        },
        {
          Title: `ЛК "Вебинары"`,
          Client: `
          Учебно-методический центр «Форус».
          `,
          Task: `
          Доработать и модернизировать систему 1С<br/>
          электронного обучения и веб-клиент.
          `,
          Solution: `
          Реализована возможность просмотра видео,<br/>
          отслеживания выполненных заданий и выставления<br/>
          метрик. Проработана рхитектура приложения,<br/>
          базы данных, бекенд запросов и контроллеров.<br/>
          Проведена вёрстка интерфейса.`,
        },
        {
          Title: `Карты`,
          Client: `
          Администрация Иркутска
          `,
          Task: `
          Реализовать сервис, где арендатор и<br/>
          арендодатель смогут взаимодействовать и<br/>
          видеть актуальную информацию об НТО.
          `,
          Solution: `
          На карте сервиса загружается вся актуальная<br/>
          информация об объектах.`,
        },
        {
          Title: `Монитор рентабельности`,
          Client: `
          Компания, занимающаяся аутсорсингом<br/>
          бухгалтерских услуг.
          `,
          Task: `
          Отслеживать показатели клиентов для анализа<br/>
          причин низкой рентабельности клиентов, групп<br/>
          или менеджеров.
          `,
          Solution: `
          Визуализированы данные из Excel в дашборде.<br/>
          Выведена рентабельность клиентов в совокупности<br/>
          по трудозатратам сотрудников, детализирована<br/>
          рентабельность по конкретным видам задач,<br/>
          которые выполнял сотрудник.`,
        },
      ],
      FeedbacksList: [
        {
          IMGName: "DimaG",
          FLName: "Дмитрий Гусаков",
          From: "Выпускник, г. Иркутск",
          Text: `«Изначально я пришёл в «Октагон» со своим<br/>
            дипломным проектом «Web-приложение йога-<br/>
            клуб».<br/>
            После реализации проекта мне предложили<br/>
            стажировку, и так как на практике я уже имел<br/>
            опыт взаимодействия с компанией и с людьми,<br/>
            которые здесь работают, у меня получилось<br/>
            быстро адаптироваться и закончить<br/>
            стажировку досрочно.<br/>
            <br/>
            Теперь я работаю на должности инженер-<br/>
            программист.<br/>
            Всем тем, кто собирается проходить практику в<br/>
            «Октагоне» желаю не бояться трудностей, ведь<br/>
            команда «Октагона» всегда поможет вам в<br/>
            реализации ваших проектов.»`,
        },
        {
          IMGName: "ViktoriaB",
          FLName: "Виктория Бубнова",
          From: "Выпускница, г. Иркутск",
          Text: `«Мой проект был связан с походной<br/>
            деятельностью – приложение для<br/>
            планирования походов как для любителей, так<br/>
            и для профессионалов. Было разработано<br/>
            приложение с адаптивной вёрсткой, в котором<br/>
            можно было создать свой поход, найти поход<br/>
            по интересам. Всем участникам «Октагона»<br/>
            желаю удачи и не бояться и быть смелыми в<br/>
            своих идеях.»`,
        },
        {
          IMGName: "SergeiB",
          FLName: "Сергей Буценко",
          From: "4 курс, Иркутск",
          Text: `«Я решил пройти стажировку для института в IT-<br/>
            компании и узнал об «Октагоне». Туда я подал<br/>
            заявку на продуктовое направление, работал над<br/>
            разными продуктами, смог прокачать свои<br/>
            компетенции и у меня получилось пройти<br/>
            стажировку в компании, устроиться на продакт-<br/>
            менеджера.<br/>
            Теперь я работаю в IT.<br/>
            <br/>
            Участникам «Октагона» хочу пожелать упорства и<br/>
            смелости. Не бойтесь идти на новые возможности!» `,
        },
      ],
      TeamList: [
        {
          IMGName: "KanterA",
          FLName: "Кантер Андрей",
          Desc: `Директор по инновациям и<br/>
            работе с учебными заведениями<br/>
            Руководитель наставников с<br/>
            опытом работы 15 лет`,
          Bubble: null,
        },
        {
          IMGName: "ZaicevI",
          FLName: "Зайцев Илья",
          Desc: `Ведущий инженер-программист<br/>
            Наставник направления: ИИ, 1С<br/>
            Более 30-ти крутых проектов<br/>
            Опыт работы 5 лет`,
          Bubble: `Когда что-то<br/>
            достаточно важно,<br/>
            вы делаете это, даже<br/>
            если шансы не в<br/>
            вашу пользу`,
        },
        {
          IMGName: "GarmaevaD",
          FLName: "Гармаева Дарья",
          Desc: `Ведущий инженер-программист<br/>
            Наставник направления: Backend, Frontend, SQL, BI<br/>
            Больше 35-ти супер проектов`,
          Bubble: `Задавать вопросы,<br/>
            если что-то непонятно`,
        },
        {
          IMGName: "KosyanskayaE",
          FLName: "Косянская Екатерина",
          Desc: `Ведущий инженер-программист<br/>
            Наставник направления: 1С, Data Science<br/>
            5 лет опыта работы,<br/>
            15+ крутых проектов`,
          Bubble: `Важно не только<br/>
            делать правильные вещи,<br/>
            но и делать вещи правильно`,
        },
        {
          IMGName: "SalovarovA",
          FLName: "Саловаров Артём",
          Desc: `Инженер-программист<br/>
          Направления: 1С, Ux/Ui<br/>
          4 года опыта работы<br/>
          20+ крутых проектов`,

          Bubble: `Я руководствуюсь принципом<br/>
            творчества и функциональности –<br/>
            создавая дизайн и программы,<br/>
            которые вдохновляют и<br/>
            решают реальные задачи.<br/>
            `,
        },
        {
          IMGName: "KraevD",
          FLName: "Краев Даниил",
          Desc: `Инженер-программист<br/>
          Наставник направления:<br/>
          Чат-боты<br/>
          3 года опыта работы
          `,

          Bubble: `Усилия и труд бесполезны<br/>
            без личной мотивации
            `,
        },
        {
          IMGName: "GrudininD",
          FLName: "Грудинин Даниил",
          Desc: `Менеджер по<br/>
          направлению<br/>
          «бизнес»<br/>
          `,

          Bubble: `Используй правило<br/>
          5-ти «Почему?»<br/>
            `,
        },
        {
          IMGName: "DosovaA",
          FLName: "Досова Алина",
          Desc: `Продуктовый менеджер<br/>
          3 года опыта работы
          `,

          Bubble: ` Качество важнее скорости.<br/>
          Лучше потратить больше<br/>
          времени на выполнение<br/>
          задачи, чем сделать ее<br/>
          поспешно и некачественно.
          `,
        },
        {
          IMGName: "LevkinaJ",
          FLName: "Левкина Жанна",
          Desc: `Менеджер по работе<br/>
          со студентами<br/>
          2 года опыта работы
          `,
          Bubble: null,
        },
        {
          IMGName: "KobulkinaT",
          FLName: "Кобылкина Татьяна",
          Desc: `Менеджер по работе<br/>
          с партнёрами<br/>
          12 лет опыта работы
          `,
          Bubble: null,
        },
      ],

      Player: null,

      VideoInBG: true,
      Options_BG: {
        volume: 0,
        autoplay: true,
        controls: false,
        sources: [
          {
            //https://mega.nz/file/m9kyGZKI#R1TQViN9ABOpnHMa0-w5_yn3-WaVvR2LiFbm6SO335o
            src: require("./../assets/video/AboutOctagon.mp4"),
            type: "video/mp4",
          },
        ],
      },

      VideoHideHeight: null,

      showRequestModal: false,
      showRegistrationModal: false,
      setTypeForModal: "",
    };
  },
  methods: {
    getFeedbackIMG(IMGName) {
      let img = require(`./../assets/img/About/FeedbacksIMG/${IMGName}.jpg`);
      return `
      background: url(${img});
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      `;
    },
    getMemberIMG(IMGName) {
      let img = require(`./../assets/img/About/Team/${IMGName}.png`);

      if (IMGName == "KanterA") {
        return `
        background: url(${img});
        background-repeat: no-repeat;
        background-position: 65% 20%;
        background-size: 140%;
      `;
      }

      if (IMGName == "KraevD") {
        return `
        background: url(${img});
        background-repeat: no-repeat;
        background-position: 70% 50%;
        background-size: 130%;
      `;
      }

      return `
      background: url(${img});
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      `;
    },

    enableVideo() {
      this.VideoInBG = false;

      this.Player.controls(true);
      this.Player.currentTime(0);
      this.Player.pause();
      this.Player.volume(0.25);
    },
    hideVideo() {
      this.VideoInBG = true;

      this.Player.controls(false);
      this.Player.currentTime(0);
      this.Player.play();
      this.Player.volume(0);
    },

    onScroll(evt, el) {
      if (this.VideoHideHeight == null) {
        return 0;
      }

      const scrollDistance = window.pageYOffset + window.innerHeight; // Вычисляем текущее расстояние скролла
      if (scrollDistance >= this.VideoHideHeight) {
        if (!this.VideoInBG) {
          this.hideVideo();
        }
        return 0; // Отключаем обработчик скролла
      }
    },

    calculateVideoHideHeight() {
      let getBlock = document.querySelector("#AboutPage > .Head");

      if (getBlock == null) {
        this.VideoHideHeight = null;
        return 0;
      }

      this.VideoHideHeight = getBlock.offsetHeight + window.innerHeight + 200;
    },

    sendFalseToParent() {
      this.showRequestModal = false;
    },
    sendCloseRegistrationModal() {
      this.showRegistrationModal = false;
    },
    openOrgRegistration(type) {
      this.setTypeForModal = type;
      this.showRegistrationModal = false;
      this.showRequestModal = true;
    },

    scrollForOurTeam() {
      let sections = gsap.utils.toArray(".OurTeam > .TeamList > .Card");

      let x = this.getCSSPropertyVal(
        ".OurTeam > .TeamList > .Card",
        "width",
        "px"
      );
      let gap_size = this.getCSSPropertyVal(".TeamList", "gap", "px");
      let padding = this.getCSSPropertyVal(".TeamList", "padding-left", "px");
      let addFormula = window.innerWidth - x - padding * 2;

      gsap.to(sections, {
        x: `-${x * (sections.length - 1) +
          gap_size * (sections.length - 1) -
          addFormula}px`,
        ease: "none",
        scrollTrigger: {
          trigger: ".OurTeam",
          pin: ".OurTeam", //В Идеале тут должен быть родитель
          pinSpacing: true,
          scrub: 1,
          end: `+=${sections.length * 250}`,
        },
      });
    },
    scrollForFeedbacks() {
      let sections = gsap.utils.toArray(
        ".ParticipantsFeedbacks > .List > .Card"
      );

      let x = this.getCSSPropertyVal(
        ".ParticipantsFeedbacks > .List > .Card",
        "width",
        "px"
      );
      let gap_size = this.getCSSPropertyVal(
        ".ParticipantsFeedbacks > .List",
        "gap",
        "px"
      );
      let margin = this.getCSSPropertyVal(
        ".ParticipantsFeedbacks > .List > .Card",
        "margin-left",
        "px"
      );

      let padding = this.getCSSPropertyVal(
        ".ParticipantsFeedbacks > .List",
        "padding-left",
        "px"
      );

      let addFormula = window.innerWidth - x - padding * 2;

      gsap.to(sections, {
        x: `-${x * (sections.length - 1) +
          gap_size * (sections.length - 1) +
          margin * 2 * (sections.length - 1) -
          addFormula}px`,
        ease: "none",
        scrollTrigger: {
          trigger: ".ParticipantsFeedbacks",
          pin: ".ParticipantsFeedbacks", //В Идеале тут должен быть родитель
          pinSpacing: true,
          scrub: 1,
          end: `+=${sections.length * 200}`,
        },
      });
    },
    scrollForCases() {
      let sections = gsap.utils.toArray(
        ".SuccessfullCases > .CasesList > .Card"
      );

      let x = this.getCSSPropertyVal(
        ".SuccessfullCases > .CasesList > .Card",
        "width",
        "px"
      );
      let gap_size = this.getCSSPropertyVal(
        ".SuccessfullCases > .CasesList",
        "gap",
        "px"
      );
      let margin = this.getCSSPropertyVal(
        ".SuccessfullCases > .CasesList > .Card",
        "margin-left",
        "px"
      );

      let padding = this.getCSSPropertyVal(
        ".SuccessfullCases > .CasesList",
        "padding-left",
        "px"
      );

      let addFormula = window.innerWidth - x - padding * 2;

      gsap.to(sections, {
        x: `-${x * (sections.length - 1) +
          gap_size * (sections.length - 1) +
          margin * 2 * (sections.length - 1) -
          addFormula}px`,
        ease: "none",
        scrollTrigger: {
          trigger: ".SuccessfullCases",
          pin: ".SuccessfullCases", //В Идеале тут должен быть родитель
          pinSpacing: true,
          scrub: 1,
          end: `+=${sections.length * 400}`,
        },
      });
    },

    getCSSPropertyVal(selector, propname, units) {
      let _El = document.querySelectorAll(selector)[0];
      let _Style = window.getComputedStyle(_El);
      let _Val = _Style.getPropertyValue(propname);
      return parseInt(_Val.substring(0, _Val.length - units.length));
    },
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);

    window.addEventListener("scroll", this.onScroll);
    window.addEventListener("resize", this.calculateVideoHideHeight());

    this.Player = videojs(this.$refs.videoPlayer, this.Options_BG, () => {});
    this.Player.volume(0);

    this.calculateVideoHideHeight();

    this.scrollForOurTeam();
    this.scrollForCases();
    this.scrollForFeedbacks();
  },
  unmounted() {
    // Вызывает проблемы с рендером между страниц
    // window.removeEventListener("scroll", this.onScroll);
    // window.removeEventListener("resize", this.calculateVideoHideHeight());
  },
};
</script>

<style scoped>
.ButtonGradient > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ButtonGradient {
  position: relative;
  display: flex;

  cursor: pointer;

  flex-direction: row;
  justify-content: center;
  gap: 8px;

  padding: 16px 28px;

  width: fit-content;
  min-width: 290px;

  border-radius: 4px;
  background: var(
    --btn,
    linear-gradient(221deg, #c548ab -3.4%, #635bff 101.08%)
  );

  transition: 0.4s;
}
.ButtonGradient:hover {
  opacity: 0.8;
}
.ButtonGradient > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ButtonGradient > .ArrowRightUp {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;

  background: url("./../assets/img/About/LightsAndParticles/arrow-right-up.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ButtonGradientOutside > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ButtonGradientOutside {
  position: relative;
  display: flex;

  cursor: pointer;

  flex-direction: row;
  justify-content: center;
  gap: 8px;

  padding: 16px 28px;

  width: fit-content;
  min-width: 290px;

  border-radius: 4px;
  border: 0px solid;
  backdrop-filter: blur(4px);

  transition: 0.4s;
}
.ButtonGradientOutside:hover {
  opacity: 0.8;
}

.ButtonGradientOutside::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 4px;
  padding: 2px;
  background: linear-gradient(221.26deg, #c548ab -3.4%, #635bff 101.08%);
  mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
  pointer-events: none;
}

.ButtonGradientOutside > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.ButtonGradientOutside > .ArrowRightUp {
  position: relative;
  display: block;

  width: 24px;
  height: 24px;

  background: url("./../assets/img/About/LightsAndParticles/arrow-right-up.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
</style>

<style scoped>
#AboutPage {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  width: 100%;
  height: fit-content;
  min-height: 100vh;

  background: radial-gradient(
    123.22% 129.67% at 100.89% -5.6%,
    #201d47 0%,
    #17153a 100%
  );

  overflow-x: hidden;
}

.StayWithUs > * {
  margin-left: auto;
  margin-right: auto;
}
.StayWithUs {
  position: relative;

  margin-top: 50px;
  padding-bottom: 120px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: 100%;
  height: fit-content;
}
.StayWithUs > .Buttons {
  position: relative;

  margin-top: 15px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  gap: 15px;

  width: fit-content;
  height: fit-content;

  z-index: 5;
}
.StayWithUs > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 100% */

  z-index: 5;
}
.StayWithUs > .Desc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  z-index: 5;
}
.StayWithUs > .Light1 {
  position: absolute;
  display: block;

  width: 769.772px;
  height: auto;

  left: calc(50% - (769.772px / 2));
  bottom: 40px;

  aspect-ratio: 1 / 0.4223;

  flex-shrink: 0;
  flex-grow: 0;

  border-radius: 233.5px;
  background: linear-gradient(
    180deg,
    rgba(84, 84, 212, 0.7) 0%,
    rgba(84, 84, 212, 0.29) 100%
  );
  filter: blur(150px);

  z-index: 1;
}

.ParticipantsFeedbacks {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  padding-top: 50px;

  width: 100%;
  height: fit-content;

  overflow-x: hidden;
}
.ParticipantsFeedbacks > .Header {
  position: relative;
  display: block;

  margin-left: auto;
  margin-right: auto;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 66.667% */
}
.ParticipantsFeedbacks > .List {
  position: relative;
  display: flex;

  padding: 0% 86px;
  padding-bottom: 20px;

  justify-content: flex-start;
  flex-direction: row;
  gap: 36px;

  width: auto;
  height: fit-content;

  overflow-x: hidden;
}

.ParticipantsFeedbacks > .List > .Card {
  position: relative;
  display: flex;

  padding: 30px;

  width: 590px;
  height: auto;

  flex-basis: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  border-radius: 10px;
  background: linear-gradient(
      119deg,
      rgba(68, 0, 255, 0.1) 25.73%,
      rgba(29, 26, 66, 0.1) 57.17%,
      rgba(68, 0, 255, 0.1) 100%
    ),
    #1d1a42;

  flex-shrink: 0;
  flex-grow: 0;
}
.ParticipantsFeedbacks > .List > .Card > .Text {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.ParticipantsFeedbacks > .List > .Card > .Info > * {
  margin-top: auto;
  margin-bottom: auto;
}
.ParticipantsFeedbacks > .List > .Card > .Info {
  position: relative;
  display: flex;

  width: 100%;
  height: fit-content;

  flex-direction: row;
  justify-content: flex-start;
  gap: 16px;
}
.ParticipantsFeedbacks > .List > .Card > .Info > .IMG {
  position: relative;
  display: block;

  width: 95px;
  height: auto;

  aspect-ratio: 1 / 1;

  border-radius: 100%;

  flex-shrink: 0;
  flex-grow: 0;
}
.ParticipantsFeedbacks > .List > .Card > .Info > .Participant {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  width: 100%;
  height: fit-content;
}
.ParticipantsFeedbacks .Info > .Participant > .NameSurname {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.ParticipantsFeedbacks .Info > .Participant > .ShortPInfo {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.OurPartners {
  position: relative;
  display: flex;

  margin-top: 50px;

  padding: 50px 120px;

  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  width: 100%;
  height: fit-content;

  overflow-x: hidden;

  background-color: white;
}
.OurPartners > span {
  position: relative;
  display: block;

  margin-left: auto;
  margin-right: auto;

  width: fit-content;
  height: fit-content;

  color: #1a183e;
  text-align: center;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px;
}
.OurPartners > .PartnersList {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: center;
  gap: 53px;

  width: 100%;
  height: fit-content;

  overflow-x: hidden;
}
.OurPartners > .PartnersList > * {
  height: 83px;
}

.OneCIMG {
  position: relative;
  display: block;

  width: auto;

  aspect-ratio: 1 / 0.5496;

  background: url("./../assets/img/About/Partners/1C.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  fill: linear-gradient(
    135deg,
    rgba(166, 192, 254, 0.5) 0%,
    rgba(109, 66, 239, 0.5) 101.5%
  );
}
.BaikalShinaIMG {
  position: relative;
  display: block;

  width: auto;

  aspect-ratio: 1 / 0.3102;

  background: url("./../assets/img/About/Partners/Baikal-Shina.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.MirMebeliIMG {
  position: relative;
  display: block;

  width: auto;

  aspect-ratio: 1 / 0.5157;

  background: url("./../assets/img/About/Partners/Mir-Mebeli.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.ForusIMG {
  position: relative;
  display: block;

  width: auto;

  aspect-ratio: 1 / 0.3804;

  background: url("./../assets/img/About/Partners/Forus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.OblastMolodihIMG {
  position: relative;
  display: block;

  width: auto;

  aspect-ratio: 1 / 0.5404;

  background: url("./../assets/img/About/Partners/Oblast-Molodih.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.SuccessfullCases {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  padding-top: 50px;

  width: 100%;
  height: fit-content;

  /* overflow-x: hidden; */
}
.SuccessfullCases > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-left: auto;
  margin-right: auto;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 40px; /* 66.667% */
}
.SuccessfullCases > .LineIMG {
  bottom: -50px;
  left: 74px;
}
.SuccessfullCases > .CasesList {
  position: relative;
  display: flex;

  padding: 0% 86px;
  padding-bottom: 20px;

  justify-content: flex-start;
  flex-direction: row;
  gap: 20px;

  width: auto;
  height: fit-content;

  overflow-x: hidden;
}
.SuccessfullCases > .CasesList > .Card {
  position: relative;
  display: flex;

  padding: 30px;

  width: 590px;
  height: auto;

  flex-basis: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 28px;

  border-radius: 10px;
  background: linear-gradient(
      119deg,
      rgba(68, 0, 255, 0.1) 25.73%,
      rgba(29, 26, 66, 0.1) 57.17%,
      rgba(68, 0, 255, 0.1) 100%
    ),
    #1d1a42;

  flex-shrink: 0;
  flex-grow: 0;
}
.SuccessfullCases > .CasesList > .Card > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 142.857% */
}
.SuccessfullCases > .CasesList > .Card > .Desc {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  width: 100%;
}
.SuccessfullCases > .CasesList > .Card > .Desc > .StaticText {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-bottom: 8px;

  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px; /* 200% */

  background: var(
    --Icons-purple,
    linear-gradient(340deg, #ab64f5 -44.29%, #62dbf6 179.47%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px; /* 125% */
}

.OurTeam {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  padding-top: 50px;

  width: 100%;
  height: fit-content;

  /* overflow-x: hidden; */
}
.OurTeam > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  margin-left: auto;
  margin-right: auto;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 116.667% */

  z-index: 4;
}
.OurTeam > .TeamList {
  position: relative;
  display: flex;

  padding: 0% 144px;
  padding-bottom: 20px;

  justify-content: flex-start;
  flex-direction: row;
  gap: 50px;

  width: auto;
  height: fit-content;

  overflow-x: hidden;
  z-index: 4;
}
.OurTeam > .Light1 {
  position: absolute;
  display: block;
  width: 467px;
  height: auto;
  left: -350px;
  top: 40px;
  aspect-ratio: 1 / 0.9192;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 233.5px;
  background: linear-gradient(
    180deg,
    rgba(84, 84, 212, 0.7) 0%,
    rgba(84, 84, 212, 0.29) 100%
  );
  filter: blur(250px);
  z-index: 1;
}
.OurTeam > .TeamList > .Card {
  position: relative;
  display: flex;

  width: 350px;
  height: auto;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;
}
.OurTeam > .TeamList > .Card > .PhotoWrap {
  position: relative;
  display: block;

  margin-bottom: 7px;

  width: 200px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background-image: linear-gradient(
    135deg,
    rgba(166, 192, 254, 0.5) 0%,
    rgba(109, 66, 239, 0.5) 101.5%
  );
  border-radius: 100%;
}
.OurTeam > .TeamList > .Card > .LastNameFirstName {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Nunito Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.75px;
}
.OurTeam > .TeamList > .Card > .Text {
  position: relative;
  display: block;

  width: 100%;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.OurTeam > .TeamList > .Card > .PhotoWrap > .TeamIMG {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background-color: #635bff;

  border-radius: 100%;
}
.OurTeam > .TeamList > .Card > .PhotoWrap > .BubbleContainer {
  position: absolute;
  display: block;
  width: fit-content;
  height: fit-content;

  top: 0;
  left: calc(100% - 48px);
}
.OurTeam .PhotoWrap > .BubbleContainer > .BubbleContent {
  position: relative;
  display: none;

  width: max-content;
  height: fit-content;

  padding: 20px;

  color: #221d41;
  font-family: "Montserrat";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  border-radius: 30px;
  background: #fff;

  z-index: 50;
}
.OurTeam .PhotoWrap > .BubbleContainer:hover > .BubbleIMG {
  display: none;
}
.OurTeam .PhotoWrap > .BubbleContainer:hover > .BubbleContent {
  display: block;
}
.FAQ {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  margin-top: 50px;

  width: 100%;
  height: fit-content;
}
.FAQ > * {
  margin-left: auto;
  margin-right: auto;
}
.FAQ > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 54px;
  font-style: normal;
  font-weight: 700;
  line-height: 54px; /* 100% */

  z-index: 5;
}
.FAQ > .Desc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  z-index: 5;
}
.FAQ > .Button {
  position: relative;
  display: block;

  padding: 12px 0%;

  width: 236px;
  height: fit-content;

  color: white;
  font-family: "Nunito Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: center;

  border-radius: 4px;
  background: linear-gradient(221deg, #c548ab -3.4%, #635bff 101.08%);

  z-index: 5;
}
.FAQ > .LineIMG {
  top: 37px;
  right: 60px;
}

.Steps > * {
  margin-left: auto;
  margin-right: auto;
}
.Steps {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 60px;

  width: 100%;
  height: fit-content;
}
.Steps > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 116.667% */
}
.Steps > .StepsList {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 36px;

  width: fit-content;
  height: fit-content;
}
.Steps > .StepsList > .Step {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
}
.Steps > .StepsList > .Step > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Nunito Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.75px;
}
.Steps > .StepsList > .Step > .Desc {
  position: relative;
  display: block;

  width: 200px;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.StepIMG1 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;

  aspect-ratio: 1 / 0.9429;

  background: url("./../assets/img/About/Steps/Step1.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.StepIMG2 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;

  aspect-ratio: 1 / 0.9429;

  background: url("./../assets/img/About/Steps/Step2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.StepIMG3 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;

  aspect-ratio: 1 / 0.9429;

  background: url("./../assets/img/About/Steps/Step3.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.StepIMG4 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;

  aspect-ratio: 1 / 0.9429;

  background: url("./../assets/img/About/Steps/Step4.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}
.StepIMG5 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4px;

  aspect-ratio: 1 / 0.9429;

  background: url("./../assets/img/About/Steps/Step5.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.Compatible {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 50px;

  width: 100%;
  height: fit-content;
}
.Compatible > * {
  margin-left: auto;
  margin-right: auto;
}
.Compatible > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  text-align: center;
  font-family: "Montserrat";
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 70px; /* 116.667% */
}
.Compatible > .LineIMG {
  top: 60%;
  left: 30px;
}
.Compatible > .CompList {
  position: relative;
  display: flex;

  flex-direction: row;
  justify-content: flex-start;
  gap: 50px;

  width: fit-content;
  height: fit-content;
}
.Compatible > .CompList > .Card {
  position: relative;
  display: flex;

  flex-direction: column;
  justify-content: flex-start;
  gap: 15px;

  width: fit-content;
  height: fit-content;
}
.Compatible > .CompList > .Card > * {
  margin-left: auto;
  margin-right: auto;
}
.Compatible > .CompList > .Card > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Nunito Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.75px;
}
.Compatible > .CompList > .Card > .DescList {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.CompIMG1 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background: url("./../assets/img/About/Comp/Comp1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.CompIMG2 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background: url("./../assets/img/About/Comp/Comp2.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.CompIMG3 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background: url("./../assets/img/About/Comp/Comp3.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.CompIMG4 {
  position: relative;
  display: block;

  width: 120px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background: url("./../assets/img/About/Comp/Comp4.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.FAQ > .Light1 {
  position: absolute;
  display: block;

  width: 756.44px;
  height: auto;

  left: calc(50% - (756.44px / 2));
  top: calc(50% - (756.44px * 0.3809 / 2));

  aspect-ratio: 1 / 0.3809;

  flex-shrink: 0;
  flex-grow: 0;

  border-radius: 233.5px;
  background: linear-gradient(
    180deg,
    rgba(84, 84, 212, 0.7) 0%,
    rgba(84, 84, 212, 0.29) 100%
  );
  filter: blur(150px);

  z-index: 1;
}

.Head {
  position: relative;
  display: block;

  width: 100%;
  height: auto;

  max-height: 1080px;

  aspect-ratio: 1 / 0.45;
}
.Head > .NavWrapper {
  position: absolute;
  display: block;

  width: 100%;
  height: fit-content;

  top: 0px;
  left: 0;

  z-index: 20;
}
.Head > .NoVideoWrapper {
  position: absolute;
  display: block;

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0;

  z-index: 10;

  backdrop-filter: blur(15px);
}
.Head > .NoVideoWrapper > .NoVideoContent {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 60px;

  padding-bottom: 8%;

  margin-left: auto;
  margin-right: auto;

  width: 1200px;
  height: 100%;

  z-index: 5;
}

.Head > .NoVideoWrapper > .NoVideoContent > .Text {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 34px;

  width: fit-content;
  height: fit-content;
}
.Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 54px;
  font-style: normal;
  font-weight: 500;
  line-height: 54px;
}
.Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title > .BOLD {
  font-weight: 700;
}
.Head > .NoVideoWrapper > .NoVideoContent > .Text > .Desc {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
  position: relative;
  display: flex;

  cursor: pointer;

  flex-direction: row;
  justify-content: flex-start;
  gap: 24px;

  width: fit-content;
  height: fit-content;
}
.Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > * {
  margin-top: auto;
  margin-bottom: auto;
}
.Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
  position: relative;
  display: block;

  width: fit-content;
  height: fit-content;

  color: #fff;
  font-family: "Montserrat";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > .PlayIMG {
  position: relative;
  display: block;

  width: 76px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background: url("./../assets/img/About/PlayButton.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.LineIMG {
  position: absolute;
  display: block;

  width: 95px;
  height: auto;

  aspect-ratio: 1 / 0.2947;

  background: url("./../assets/img/About/Line.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
.BubbleIMG {
  position: relative;
  display: block;

  cursor: pointer;

  width: 48px;
  height: auto;

  aspect-ratio: 1 / 1;

  flex-shrink: 0;
  flex-grow: 0;

  background: url("./../assets/img/About/Bubble.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.Head > .VideoWrapper {
  position: absolute;
  display: block;

  width: 100%;
  height: 100%;

  top: 0;
  left: 0;

  z-index: 5;

  overflow: hidden;
}
.Head > .VideoWrapper > .Video {
  position: absolute;
  display: block;

  width: 100%;
  height: auto;

  aspect-ratio: 1 / 0.5625;

  top: 0;
  left: 0;

  z-index: 2;

  transform: translateY(-25%);
}

.Head > .NoVideoWrapper > .LeftLightEffect {
  position: absolute;
  display: block;
  top: -295px;
  left: 233px;
  width: 467px;
  height: 1371px;
  aspect-ratio: 1 / 0.3787;
  border-radius: 233.5px;
  background: linear-gradient(
    180deg,
    rgba(84, 84, 212, 0.5) 0%,
    rgba(84, 84, 212, 0.21) 100%
  );
  filter: blur(150px);
  z-index: 1;
  transform: rotate(-50deg);

  opacity: 0.8;
}
.Head > .NoVideoWrapper > .RightLightEffect {
  position: absolute;
  display: block;
  top: -93px;
  right: 211px;
  width: 425px;
  height: 1620px;
  aspect-ratio: 1 / 0.3787;
  border-radius: 233.5px;
  background: linear-gradient(
    180deg,
    rgba(251, 168, 28, 0.16) 0%,
    rgba(224, 86, 136, 0.09) 100%
  );
  filter: blur(150px);
  z-index: 1;
  transform: rotate(50deg);
}

.Head > .NoVideoWrapper > .OctagonTransparentParticle {
  position: absolute;
  display: block;

  width: 971px;
  height: auto;

  opacity: 0.3;

  aspect-ratio: 1 / 1;

  background: url("./../assets/img/About/OctaTransparentParticle.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  top: 0;
  right: -120px;
}

@import url("./../../node_modules/video.js/dist/video-js.css");
</style>

<style scoped>
@media (max-width: 480px) {
  #AboutPage .OurTeam > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .ParticipantsFeedbacks > .Header {
    padding-top: 50px;
  }

  .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin: 0% auto;
  }

  #AboutPage .VideoWrapper > .VideoIsActive {
    opacity: 1;
    width: 100% !important;
    transform: none !important;
    min-width: auto !important;
  }

  #AboutPage .StayWithUs > .Buttons {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }

  #AboutPage .Steps > .StepsList {
    flex-direction: column;
    justify-content: center;
    gap: 45px;
    width: 90%;
  }
  #AboutPage .Steps > .StepsList > .Step {
    width: fit-content;
    height: fit-content;
    flex-shrink: 1;
    flex-grow: 0;
    margin-left: auto;
    margin-right: auto;
  }

  #AboutPage .OurTeam .PhotoWrap > .BubbleContainer > .BubbleContent {
    padding: 16px;
    font-size: 14px;
  }

  #AboutPage .BubbleIMG {
    width: 36px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap > .BubbleContainer {
    left: calc(100% - 36px);
  }

  #AboutPage .FAQ > .LineIMG {
    display: none;
  }

  #AboutPage .Compatible > .LineIMG {
    display: none;
  }
  #AboutPage .Compatible > .CompList {
    gap: 25px;
    flex-direction: column;
    justify-content: center;
  }
  #AboutPage .Compatible > .CompList > .Card {
    width: 90%;
  }

  #AboutPage .SuccessfullCases > .CasesList {
    padding: 0% 60px;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList {
    padding: 0% 70px;
    padding-bottom: 20px;
    gap: 20px;
  }

  #AboutPage .ParticipantsFeedbacks > .List {
    padding: 0%;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList > .Card {
    width: 250px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin-bottom: 7px;
    width: 120px;
  }

  #AboutPage .OurPartners > .PartnersList {
    overflow-x: auto;
    justify-content: flex-start;

    width: fit-content;

    margin-left: auto;
    margin-right: auto;

    gap: 15px;
  }
  #AboutPage .OurPartners {
    padding-left: 0;
    padding-right: 0;
  }
  #AboutPage .OurPartners > .PartnersList > * {
    height: 20px;
  }

  /* v Every block title & desc v */
  #AboutPage .StayWithUs > .Title,
  #AboutPage .ParticipantsFeedbacks > .Header,
  #AboutPage .OurPartners > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .OurTeam > span,
  #AboutPage .Compatible > .Title,
  #AboutPage .Steps > .Title,
  #AboutPage .FAQ > .Title {
    font-size: 22px;
    line-height: 32px;
  }

  #AboutPage .FAQ > .Desc,
  #AboutPage .StayWithUs > .Desc {
    font-size: 16px;
  }
  /* ^ Every block title & desc ^ */

  #AboutPage .StepIMG1,
  #AboutPage .StepIMG2,
  #AboutPage .StepIMG3,
  #AboutPage .StepIMG4,
  #AboutPage .StepIMG5 {
    width: 60px;
  }

  #AboutPage .CompIMG1,
  #AboutPage .CompIMG2,
  #AboutPage .CompIMG3,
  #AboutPage .CompIMG4 {
    width: 70px;
  }

  #AboutPage .SuccessfullCases > .CasesList {
    padding: 0%;
    padding-bottom: 20px;
  }
  #AboutPage .SuccessfullCases > .CasesList > .Card {
    width: calc(100% - 30px);
    margin: 0% 15px;
  }

  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Info > .IMG {
    width: 60px;
  }

  #AboutPage .ParticipantsFeedbacks > .List > .Card {
    width: calc(100% - 30px);
    margin: 0% 15px;
  }

  #AboutPage .OurTeam > .TeamList > .Card > .LastNameFirstName,
  #AboutPage .SuccessfullCases > .CasesList > .Card > span,
  #AboutPage .Steps > .StepsList > .Step > .Title,
  #AboutPage .Compatible > .CompList > .Card > .Title {
    font-size: 20px;
    width: fit-content;
    max-width: 100%;
    margin: 0% auto;
    text-align: center;
  }

  #AboutPage .Steps > .StepsList > .Step > .Desc {
    width: fit-content;
  }
  #AboutPage .Steps > .StepsList {
    gap: 20px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    line-height: 145%;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    margin-bottom: 8px;
  }

  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .NameSurname,
  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .ShortPInfo,
  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Text,
  #AboutPage .OurTeam > .TeamList > .Card > .Text,
  #AboutPage .Steps > .StepsList > .Step > .Desc,
  #AboutPage .Compatible > .CompList > .Card > .DescList {
    font-size: 14px;
    margin-left: 0px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    font-size: 12px;
    margin-left: 0px;
  }

  /* v HeadBlock v */
  #AboutPage > .Head > .NoVideoWrapper > .OctagonTransparentParticle {
    display: none;
  }
  #AboutPage > .Head > .NoVideoWrapper > .LeftLightEffect {
    display: none;
  }
  #AboutPage > .Head > .NoVideoWrapper > .RightLightEffect {
    display: none;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent {
    gap: 20px;
    padding-bottom: 100px;

    width: 90%;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text {
    gap: 12px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
    font-size: 17px;
    line-height: 18px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Desc {
    font-size: 12px;
    line-height: normal;
  }

  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
    gap: 8px;
  }
  #AboutPage
    > .Head
    > .NoVideoWrapper
    > .NoVideoContent
    > .PlayButton
    > .PlayIMG {
    width: 34px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
    font-size: 13px;
  }

  #AboutPage > .Head > .NavWrapper {
    top: 0%;
  }

  #AboutPage > .Head {
    height: 80svh;
    min-height: 350px;
    max-height: 500px;
  }

  #AboutPage > .Head > .VideoWrapper > .Video {
    width: auto;
    height: 100%;

    aspect-ratio: 1 / 0.5625;

    transform: translateX(-25%);
    min-width: 125%;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  #AboutPage .OurTeam > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .ParticipantsFeedbacks > .Header {
    padding-top: 50px;
  }

  .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin: 0% auto;
  }

  #AboutPage .VideoWrapper > .VideoIsActive {
    opacity: 1;
    width: 100% !important;
    transform: none !important;
    min-width: auto !important;
  }

  #AboutPage .StayWithUs > .Buttons {
    flex-direction: column;
    justify-content: center;
    gap: 15px;
  }

  #AboutPage .Steps > .StepsList {
    flex-direction: column;
    justify-content: center;
    gap: 45px;
    width: 90%;
  }
  #AboutPage .Steps > .StepsList > .Step {
    width: fit-content;
    height: fit-content;
    flex-shrink: 1;
    flex-grow: 0;
    margin-left: auto;
    margin-right: auto;
  }

  #AboutPage .OurTeam .PhotoWrap > .BubbleContainer > .BubbleContent {
    padding: 16px;
    font-size: 14px;
  }

  #AboutPage .BubbleIMG {
    width: 36px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap > .BubbleContainer {
    left: calc(100% - 36px);
  }

  #AboutPage .FAQ > .LineIMG {
    display: none;
  }

  #AboutPage .Compatible > .LineIMG {
    display: none;
  }
  #AboutPage .Compatible > .CompList {
    gap: 25px;
    flex-direction: column;
    justify-content: center;
  }
  #AboutPage .Compatible > .CompList > .Card {
    width: 90%;
  }

  #AboutPage .SuccessfullCases > .CasesList {
    padding: 0%;
    padding-bottom: 20px;
  }
  #AboutPage .SuccessfullCases > .CasesList > .Card {
    width: 450px;
    margin: 0% 15px;
  }

  #AboutPage .OurTeam > .TeamList {
    padding: 0% 70px;
    padding-bottom: 20px;
    gap: 20px;
  }

  #AboutPage .ParticipantsFeedbacks > .List {
    padding: 0%;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList > .Card {
    width: 250px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin-bottom: 7px;
    width: 120px;
  }

  #AboutPage .OurPartners > .PartnersList {
    overflow-x: auto;
    justify-content: flex-start;

    width: fit-content;

    margin-left: auto;
    margin-right: auto;

    gap: 15px;
  }
  #AboutPage .OurPartners {
    padding-left: 0;
    padding-right: 0;
  }
  #AboutPage .OurPartners > .PartnersList > * {
    height: 30px;
  }

  /* v Every block title & desc v */
  #AboutPage .StayWithUs > .Title,
  #AboutPage .ParticipantsFeedbacks > .Header,
  #AboutPage .OurPartners > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .OurTeam > span,
  #AboutPage .Compatible > .Title,
  #AboutPage .Steps > .Title,
  #AboutPage .FAQ > .Title {
    font-size: 22px;
    line-height: 32px;
  }

  #AboutPage .FAQ > .Desc,
  #AboutPage .StayWithUs > .Desc {
    font-size: 16px;
  }
  /* ^ Every block title & desc ^ */

  #AboutPage .StepIMG1,
  #AboutPage .StepIMG2,
  #AboutPage .StepIMG3,
  #AboutPage .StepIMG4,
  #AboutPage .StepIMG5 {
    width: 60px;
  }

  #AboutPage .CompIMG1,
  #AboutPage .CompIMG2,
  #AboutPage .CompIMG3,
  #AboutPage .CompIMG4 {
    width: 70px;
  }

  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Info > .IMG {
    width: 60px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card {
    width: 450px;
  }
  #AboutPage .ParticipantsFeedbacks > .List > .Card {
    width: 450px;
    margin: 0% 15px;
  }

  #AboutPage .OurTeam > .TeamList > .Card > .LastNameFirstName,
  #AboutPage .SuccessfullCases > .CasesList > .Card > span,
  #AboutPage .Steps > .StepsList > .Step > .Title,
  #AboutPage .Compatible > .CompList > .Card > .Title {
    font-size: 20px;
    width: fit-content;
    max-width: 100%;
    margin: 0% auto;
    text-align: center;
  }

  #AboutPage .Steps > .StepsList > .Step > .Desc {
    width: fit-content;
  }
  #AboutPage .Steps > .StepsList {
    gap: 20px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    line-height: 145%;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    margin-bottom: 8px;
  }

  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .NameSurname,
  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .ShortPInfo,
  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Text,
  #AboutPage .OurTeam > .TeamList > .Card > .Text,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc,
  #AboutPage .Steps > .StepsList > .Step > .Desc,
  #AboutPage .Compatible > .CompList > .Card > .DescList {
    font-size: 14px;
    margin-left: 0px;
  }

  /* v HeadBlock v */
  #AboutPage > .Head > .NoVideoWrapper > .OctagonTransparentParticle {
    width: 501px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .LeftLightEffect {
    height: 852px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .RightLightEffect {
    height: 697px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent {
    gap: 20px;
    padding-bottom: 100px;

    width: 80%;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text {
    gap: 15px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
    font-size: 22px;
    line-height: 22px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Desc {
    font-size: 16px;
    line-height: normal;
  }

  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
    gap: 8px;
  }
  #AboutPage
    > .Head
    > .NoVideoWrapper
    > .NoVideoContent
    > .PlayButton
    > .PlayIMG {
    width: 34px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
    font-size: 15px;
  }

  #AboutPage > .Head > .NavWrapper {
    top: 0%;
  }

  #AboutPage > .Head {
    height: 80svh;

    min-height: 480px;
    max-height: 550px;
  }

  #AboutPage > .Head > .VideoWrapper > .Video {
    width: auto;
    height: 100%;

    aspect-ratio: 1 / 0.5625;

    transform: translateX(-25%);
    min-width: 125%;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  #AboutPage .OurTeam .PhotoWrap > .BubbleContainer > .BubbleContent {
    padding: 16px;
    font-size: 14px;
  }

  #AboutPage .BubbleIMG {
    width: 36px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap > .BubbleContainer {
    left: calc(100% - 36px);
  }

  #AboutPage .FAQ > .LineIMG[data-v-039c5b43] {
    top: 27px;
    right: 10px;
  }

  #AboutPage .Compatible > .LineIMG {
    display: none;
  }
  #AboutPage .Compatible > .CompList {
    gap: 15px;
  }

  #AboutPage .SuccessfullCases > .CasesList {
    padding: 0% 60px;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList {
    padding: 0% 70px;
    padding-bottom: 20px;
    gap: 20px;
  }

  #AboutPage .ParticipantsFeedbacks > .List {
    padding: 0% 60px;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList > .Card {
    width: 250px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin-bottom: 7px;
    width: 120px;
  }

  #AboutPage .OurPartners > .PartnersList {
    overflow-x: auto;
    justify-content: flex-start;

    width: fit-content;

    margin-left: auto;
    margin-right: auto;

    gap: 30px;
  }
  #AboutPage .OurPartners {
    padding-left: 0;
    padding-right: 0;
  }
  #AboutPage .OurPartners > .PartnersList > * {
    height: 50px;
  }

  /* v Every block title & desc v */
  #AboutPage .StayWithUs > .Title,
  #AboutPage .ParticipantsFeedbacks > .Header,
  #AboutPage .OurPartners > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .OurTeam > span,
  #AboutPage .Compatible > .Title,
  #AboutPage .Steps > .Title,
  #AboutPage .FAQ > .Title {
    font-size: 26px;
    line-height: 36px;
  }

  #AboutPage .FAQ > .Desc,
  #AboutPage .StayWithUs > .Desc {
    font-size: 16px;
  }
  /* ^ Every block title & desc ^ */

  #AboutPage .StepIMG1,
  #AboutPage .StepIMG2,
  #AboutPage .StepIMG3,
  #AboutPage .StepIMG4,
  #AboutPage .StepIMG5 {
    width: 60px;
  }

  #AboutPage .CompIMG1,
  #AboutPage .CompIMG2,
  #AboutPage .CompIMG3,
  #AboutPage .CompIMG4 {
    width: 70px;
  }

  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Info > .IMG {
    width: 60px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card {
    width: 450px;
  }
  #AboutPage .ParticipantsFeedbacks > .List > .Card {
    width: 480px;
  }

  #AboutPage .OurTeam > .TeamList > .Card > .LastNameFirstName,
  #AboutPage .SuccessfullCases > .CasesList > .Card > span,
  #AboutPage .Steps > .StepsList > .Step > .Title,
  #AboutPage .Compatible > .CompList > .Card > .Title {
    font-size: 16px;
  }

  #AboutPage .Steps > .StepsList > .Step > .Desc {
    width: fit-content;
  }
  #AboutPage .Steps > .StepsList {
    gap: 20px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    line-height: 145%;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    margin-bottom: 8px;
  }

  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .NameSurname,
  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .ShortPInfo,
  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Text,
  #AboutPage .OurTeam > .TeamList > .Card > .Text,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc,
  #AboutPage .Steps > .StepsList > .Step > .Desc,
  #AboutPage .Compatible > .CompList > .Card > .DescList {
    font-size: 12px;
  }

  /* v HeadBlock v */
  #AboutPage > .Head > .NoVideoWrapper > .OctagonTransparentParticle {
    width: 451px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .LeftLightEffect {
    height: 852px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .RightLightEffect {
    height: 697px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent {
    gap: 20px;
    padding-bottom: 30px;

    width: 600px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text {
    gap: 15px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
    font-size: 22px;
    line-height: 22px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Desc {
    font-size: 16px;
    line-height: normal;
  }

  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
    gap: 8px;
  }
  #AboutPage
    > .Head
    > .NoVideoWrapper
    > .NoVideoContent
    > .PlayButton
    > .PlayIMG {
    width: 34px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
    font-size: 15px;
  }

  #AboutPage > .Head > .NavWrapper {
    top: 0%;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  #AboutPage .SuccessfullCases > .CasesList {
    padding: 0% 60px;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList {
    padding: 0% 70px;
    padding-bottom: 20px;
    gap: 40px;
  }

  #AboutPage .ParticipantsFeedbacks > .List {
    padding: 0% 60px;
    padding-bottom: 20px;
  }

  #AboutPage .OurTeam > .TeamList > .Card {
    width: 300px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin-bottom: 7px;
    width: 160px;
  }

  #AboutPage .OurPartners > .PartnersList {
    overflow-x: auto;
    justify-content: flex-start;

    width: fit-content;

    margin-left: auto;
    margin-right: auto;
  }
  #AboutPage .OurPartners {
    padding-left: 0;
    padding-right: 0;
  }
  #AboutPage .OurPartners > .PartnersList > * {
    height: 60px;
  }

  /* v Every block title & desc v */
  #AboutPage .StayWithUs > .Title,
  #AboutPage .ParticipantsFeedbacks > .Header,
  #AboutPage .OurPartners > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .OurTeam > span,
  #AboutPage .Compatible > .Title,
  #AboutPage .Steps > .Title,
  #AboutPage .FAQ > .Title {
    font-size: 32px;
    line-height: 42px;
  }

  #AboutPage .FAQ > .Desc,
  #AboutPage .StayWithUs > .Desc {
    font-size: 18px;
  }
  /* ^ Every block title & desc ^ */

  #AboutPage .StepIMG1,
  #AboutPage .StepIMG2,
  #AboutPage .StepIMG3,
  #AboutPage .StepIMG4,
  #AboutPage .StepIMG5 {
    width: 80px;
  }

  #AboutPage .CompIMG1,
  #AboutPage .CompIMG2,
  #AboutPage .CompIMG3,
  #AboutPage .CompIMG4 {
    width: 100px;
  }

  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Info > .IMG {
    width: 80px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card {
    width: 450px;
  }
  #AboutPage .ParticipantsFeedbacks > .List > .Card {
    width: 480px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > span,
  #AboutPage .Steps > .StepsList > .Step > .Title,
  #AboutPage .Compatible > .CompList > .Card > .Title {
    font-size: 18px;
  }

  #AboutPage .Steps > .StepsList > .Step > .Desc {
    width: fit-content;
  }
  #AboutPage .Steps > .StepsList {
    gap: 40px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    line-height: 145%;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc {
    margin-bottom: 8px;
  }

  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .StaticText,
  #AboutPage .SuccessfullCases > .CasesList > .Card > .Desc > .CaseDesc,
  #AboutPage .Steps > .StepsList > .Step > .Desc,
  #AboutPage .Compatible > .CompList > .Card > .DescList {
    font-size: 14px;
  }

  /* v HeadBlock v */
  #AboutPage > .Head > .NoVideoWrapper > .OctagonTransparentParticle {
    width: 551px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .LeftLightEffect {
    height: 1052px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .RightLightEffect {
    height: 897px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent {
    gap: 40px;
    padding-bottom: 70px;

    width: 800px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text {
    gap: 12px;
  }

  #AboutPage .OurTeam > .TeamList > .Card > .LastNameFirstName,
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
    font-size: 22px;
    line-height: 22px;
  }

  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .ShortPInfo,
  #AboutPage .ParticipantsFeedbacks .Info > .Participant > .NameSurname,
  #AboutPage .ParticipantsFeedbacks > .List > .Card > .Text,
  #AboutPage .OurTeam > .TeamList > .Card > .Text,
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Desc {
    font-size: 16px;
    line-height: normal;
  }

  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
    gap: 12px;
  }
  #AboutPage
    > .Head
    > .NoVideoWrapper
    > .NoVideoContent
    > .PlayButton
    > .PlayIMG {
    width: 40px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
    font-size: 16px;
  }

  #AboutPage > .Head > .NavWrapper {
    top: 0%;
  }
}
@media (min-width: 1200px) and (max-width: 1400px) {
  #AboutPage .OurTeam > .TeamList > .Card {
    width: 300px;
  }
  #AboutPage .OurTeam > .TeamList > .Card > .PhotoWrap {
    margin-bottom: 7px;
    width: 180px;
  }

  #AboutPage .OurPartners > .PartnersList {
    overflow-x: auto;
    justify-content: flex-start;

    width: fit-content;

    margin-left: auto;
    margin-right: auto;
  }
  #AboutPage .OurPartners {
    padding-left: 0;
    padding-right: 0;
  }
  #AboutPage .OurPartners > .PartnersList > * {
    height: 70px;
  }

  /* v Every block title & desc v */
  #AboutPage .StayWithUs > .Title,
  #AboutPage .ParticipantsFeedbacks > .Header,
  #AboutPage .OurPartners > span,
  #AboutPage .SuccessfullCases > span,
  #AboutPage .OurTeam > span,
  #AboutPage .Compatible > .Title,
  #AboutPage .Steps > .Title,
  #AboutPage .FAQ > .Title {
    font-size: 40px;
    line-height: 50px;
  }

  #AboutPage .FAQ > .Desc,
  #AboutPage .StayWithUs > .Desc {
    font-size: 20px;
  }
  /* ^ Every block title & desc ^ */

  #AboutPage .StepIMG1,
  #AboutPage .StepIMG2,
  #AboutPage .StepIMG3,
  #AboutPage .StepIMG4,
  #AboutPage .StepIMG5 {
    width: 100px;
  }

  #AboutPage .Steps > .StepsList > .Step > .Title,
  #AboutPage .Compatible > .CompList > .Card > .Title {
    font-size: 22px;
  }

  #AboutPage .Steps > .StepsList > .Step > .Desc {
    width: fit-content;
  }
  #AboutPage .Steps > .StepsList {
    gap: 50px;
  }

  #AboutPage .Steps > .StepsList > .Step > .Desc,
  #AboutPage .Compatible > .CompList > .Card > .DescList {
    font-size: 15px;
  }

  /* v HeadBlock v */
  #AboutPage > .Head > .NoVideoWrapper > .OctagonTransparentParticle {
    width: 551px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .LeftLightEffect {
    height: 1052px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .RightLightEffect {
    height: 897px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent {
    gap: 60px;
    padding-bottom: 70px;

    width: 900px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text {
    gap: 15px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
    font-size: 26px;
    line-height: 26px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Desc {
    font-size: 18px;
    line-height: normal;
  }

  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
    gap: 12px;
  }
  #AboutPage
    > .Head
    > .NoVideoWrapper
    > .NoVideoContent
    > .PlayButton
    > .PlayIMG {
    width: 40px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
    font-size: 16px;
  }

  #AboutPage > .Head > .NavWrapper {
    top: 0%;
  }
}
@media (min-width: 1400px) and (max-width: 1600px) {
  #AboutPage > .Head > .NoVideoWrapper > .OctagonTransparentParticle {
    width: 651px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .LeftLightEffect {
    height: 1052px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .RightLightEffect {
    height: 1077px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent {
    gap: 60px;
    padding-bottom: 70px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .Text > .Title {
    font-size: 40px;
    line-height: 40px;
  }

  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton {
    gap: 16px;
  }
  #AboutPage
    > .Head
    > .NoVideoWrapper
    > .NoVideoContent
    > .PlayButton
    > .PlayIMG {
    width: 56px;
  }
  #AboutPage > .Head > .NoVideoWrapper > .NoVideoContent > .PlayButton > span {
    font-size: 18px;
  }
}
@media (min-width: 1921px) {
}
</style>

<style>
#AboutPage > .Head > .VideoWrapper > .Video > .video-js {
  width: 100%;
  height: 100%;
}
</style>
